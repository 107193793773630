var cookieconsent;
window.addEventListener('load', function() {
    cookieconsent = initCookieConsent();
    cookieconsent.run({
        "autorun": true,
        "current_lang": "default",
        "page_scripts": true,
        "gui_options": {
            "consent_modal": {
                "layout": "cloud",
                "position": "bottom center",
                "transition": "slide"
            },
            "settings_modal": {
                "layout": "box",
                "transition": "slide"
            }
        },
        "languages": {
            "default": {
                "consent_modal": {
                    "title": "🍪 na oblečení nevidíme rádi, ale na webu je máme",
                    "description": "Máte rádi 🍪 ? My také... Ale zde nám 🍪 umožňují zlepšit Váš zážitek z prohlížení našeho webu. Můžeme tak zjistit, zdali se naše vynaložené úsílí vyplácí. Budeme moc rádi, když udělíte souhlas. Děkujeme !",
                    "primary_btn": {
                        "text": "Povolit všechny cookies",
                        "role": "accept_all"
                    },
                    "secondary_btn": {
                        "text": "Nastavení",
                        "role": "settings"
                    }
                },
                "settings_modal": {
                    "title": "Nastavení cookies",
                    "save_settings_btn": "Uložit",
                    "accept_all_btn": "Přijmout všechny cookies",
                    "reject_all_btn": "Odmítnout vše",
                    "blocks": [{
                        "title": "Co jsou cookies a kde je používáme?",
                        "description": "Použití cookies na naší stránce se neliší od jiných webů. Pomáhá nám k lepší funkcionalitě, získávání informací, co Vás na webu zajímá a díky tomu jsme schopni web vylepšovat k Vaší spokojenosti."
                    }, {
                        "title": "Nezbytné",
                        "description": "Nezbytné cookies potřebujeme pro to, aby web mohl správně fungovat (například košík si pamatuje Vámi vložené oblečení).",
                        "toggle": {
                            "value": "necessary",
                            "enabled": true,
                            "readonly": true
                        }
                    }, {
                        "title": "Analytické",
                        "description": "Pomáhají nám zjistit, co Vás na webu zajímá, které oblečení máte nejraději.",
                        "toggle": {
                            "value": "analytics",
                            "enabled": false,
                            "readonly": false
                        }
                    }, {
                        "title": "Marketingové",
                        "description": "Pokud necháte zapnuté, bude se Vám občas zobrazovat reklama s našim oblečením.",
                        "toggle": {
                            "value": "marketing",
                            "enabled": false,
                            "readonly": false
                        }
                    }]
                }
            }
        },
        onFirstAction: function(user_preferences, cookie) {

            try {

                let consentSettings = {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied',
                };

                if (cookie.level.includes('analytics')) {
                    consentSettings['analytics_storage'] = 'granted';
                }

                if (cookie.level.includes('marketing')) {
                    consentSettings['ad_user_data'] = 'granted';
                    consentSettings['ad_storage'] = 'granted';
                    consentSettings['ad_personalization'] = 'granted';
                }

                gtag('consent', 'update', consentSettings);

            } catch (e){}

        }
    });
});